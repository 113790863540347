import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { PostItem, BrandDetails, Layout } from '../components';
import { Container, Column, PostsWrapper } from '../styles/shared';

const Branded = ({
  data: {
    branded: { edges: brandedEdges },
  },
}) => {
  const [clicked, setClicked] = React.useState('');

  const handleClick = (e) => setClicked(e.currentTarget.id);

  return (
    <Layout>
      <Container>
        <Column>
          <PostsWrapper>
            {brandedEdges.map(({ node }) => (
              <PostItem
                key={node.fields.slug}
                path={node.fields.slug}
                thumbnail={node.frontmatter.thumbnail.childImageSharp.fluid}
                // title={node.frontmatter.title}
                handleClick={handleClick}
                clicked={clicked}
                title={node.frontmatter.brand}
                link={node.frontmatter.link}
                type="Branded"
              >
                <BrandDetails
                  title={node.frontmatter.title}
                  brand={node.frontmatter.brand}
                />
              </PostItem>
            ))}
          </PostsWrapper>
        </Column>
      </Container>
    </Layout>
  );
};

export default Branded;

Branded.propTypes = {
  data: PropTypes.shape({
    branded: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query BrandedQuery {
    branded: allMarkdownRemark(
      limit: 12
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { sourceInstanceName: { eq: "branded" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            brand
            link
            title
            thumbnail {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
